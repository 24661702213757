headerH = $('#l-header').outerHeight(true)

FixedGnavi = ->
  scroll = $(window).scrollTop()
  if scroll >= $('.l-content').offset().top
    $('#l-header').addClass('is-fixed')
  else
    $('#l-header').removeClass('is-fixed')
  return

$(window).on('scroll',
  ()->
    FixedGnavi()
)

$(window).on('load',
  ()->
    # $('.c-loader').addClass('is-loaded')
    FixedGnavi()
)

$('.navi__list a').on('click',
  (e)->
    headerVal = $('#l-header').outerHeight(true)
    scroll = $(window).scrollTop()
    adjust = 0
    if scroll <= headerVal
      adjust = 40
    elmHash = $(this).attr('href')
    pos = $(elmHash).offset().top - headerVal + adjust
    $('body,html').animate({
      scrollTop: pos
    },1000
    )

    if $('.navi').hasClass('is-active')
      $('body').removeClass('is-open')
      $('.navi').removeClass('is-active')
      $('.hum').removeClass('is-active')
    else
      $('body').addClass('is-open')
      $('.navi').addClass('is-active')
      $('.hum').addClass('is-active')
)

$('.hum').on('click',
  (e)->
    if $('.navi').hasClass('is-active')
      $('body').removeClass('is-open')
      $('.navi').removeClass('is-active')
      $('.hum').removeClass('is-active')
    else
      $('body').addClass('is-open')
      $('.navi').addClass('is-active')
      $('.hum').addClass('is-active')
)
